"use strict";

import "regulus-oscar";
import "./components/sticky-header";

$(function () {
    const accordion = $('#accordion');
    accordion.on('hidden.bs.collapse', function () {
        $('#accordion #see-more')
            .removeClass("d-none");
        $('#accordion #see-less')
            .addClass("d-none");
    });
    accordion.on('shown.bs.collapse', function () {
        $('#accordion #see-more')
            .addClass("d-none");
        $('#accordion #see-less')
            .removeClass("d-none");
    });

    const toggleAccordionVisibility = function () {
        const productLongDescContent = $(".product-long-desc__content");
        const content = productLongDescContent.html();
        const trimedContent = $.trim(content);

        if (trimedContent === "") {
            $("#accordion").addClass("d-none");
        } else {
            $("#accordion").removeClass("d-none");
        }
    }
    toggleAccordionVisibility();
});

$('.slider').each(function (_, element) {
    const slider = $(element);
    const sliderContainer = slider.find('.slider-container');
    const cards = sliderContainer.children();
    const cardWidth = cards.first().outerWidth(true);
    sliderContainer.append(cards.clone());

    const Direction = {
        Next: 'Next',
        Previous: 'Previous'
    };

    function scrollSliders(direction) {
        const currentScroll = sliderContainer.scrollLeft();
        const maxScroll = cardWidth * cards.length;

        let newScroll;
        if (direction === Direction.Next) {
            newScroll = currentScroll + cardWidth;
            if (newScroll >= maxScroll) {
                sliderContainer.scrollLeft(0);
                newScroll = cardWidth;
            }
        } else if (direction === Direction.Previous) {
            newScroll = currentScroll - cardWidth;
            if (newScroll < 0) {
                sliderContainer.scrollLeft(maxScroll - cardWidth);
                newScroll = maxScroll - cardWidth * 2;
            }
        } else {
            throw new Error("Invalid direction");
        }

        sliderContainer.animate({
            scrollLeft: newScroll
        }, 300);
    }

    slider.find('.js-slider-previous').click(() => scrollSliders(Direction.Previous));
    slider.find('.js-slider-next').click(() => scrollSliders(Direction.Next));

    const scrollInterval = 3000;
    setInterval(() => scrollSliders(Direction.Next), scrollInterval);
});